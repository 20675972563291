import styled from '@emotion/styled'
import { IconSeparator } from 'app/components/Common/IconSeparator'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Filters rooms={rooms} />
      <IconSeparator className="separator" />
      <Rooms>
        {uniq(rooms).map((item, index) => (
          <Room key={index} {...item} />
        ))}
      </Rooms>
    </Container>
  )
})

const Container = styled.section`
  margin-top: -12.5rem;

  @media (max-width: 1023px) {
    margin-top: -6.25rem;
  }

  @media (max-width: 767px) {
    margin-top: 0;

    .separator {
      margin-bottom: 1.875rem;
    }
  }
`

const Rooms = styled.div``
