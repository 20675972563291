import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        if (!item.title) {
          return undefined
        }

        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-150}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {item.title}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 2.375rem 1.875rem;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 3;
  &.fixed {
    animation: filtersFadeIn 0.3s;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    position: fixed;
    top: 4.375rem;
  }

  @keyframes filtersFadeIn {
    0% {
      top: 0;
    }
    100% {
      top: 4.375rem;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Filter = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.0313rem;
  line-height: 1.5rem;
  margin: 0 1.875rem;
  position: relative;
  transition: 0.2s ease-in-out;
  &.active {
    color: ${({ theme }) => theme.colors.variants.primaryDark2};
  }
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1023px) {
    margin: 0 0.625rem;
  }
`
